import React from "react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { Autoplay, EffectFade, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "gatsby";
import SiteMainVisualSBaC from "~/images/SiteMainVisualSB&C.png";
import SiteMainVisualOMRON from "~/images/SiteMainVisualOMRON.png";
import siteMainVisualFindy from "~/images/SiteMainVisualFindy.png";

export const MainVisualSwiper = () => {
  return (
    <Swiper
      pagination={true}
      modules={[EffectFade, Autoplay, Pagination]}
      loop={true}
      effect="fade"
      autoplay={{
        delay: 7000,
        disableOnInteraction: false,
      }}
      style={{ width: "100%" }}
    >
      <SwiperSlide key="slide-1">
        <Link to={`/cases/4oDH3961iSvtSbWCVxOnBT`}>
          <img src={SiteMainVisualSBaC} style={{ width: "100%" }} />
        </Link>
      </SwiperSlide>
      <SwiperSlide key="slide-2">
        <Link to={`/cases/5eSQdSCQulCzyMNSYgPoIT`}>
          <img src={SiteMainVisualOMRON} style={{ width: "100%" }} />
        </Link>
      </SwiperSlide>
      <SwiperSlide key="slide-3">
        <Link to={`/cases/6WkJwT5oM2DNAdWxVJiTL4`}>
          <img src={siteMainVisualFindy} style={{ width: "100%" }} />
        </Link>
      </SwiperSlide>
    </Swiper>
  );
};
