import React, { FC } from "react";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";
import { IoMdArrowForward } from "@react-icons/all-files/io/IoMdArrowForward";

type InPageLinksType = {};

export const InPageLinks: FC<InPageLinksType> = () => {
  return (
    <>
      <div className="container">
        <a href="#aboutReferral" className="link">
          <span className="linkText">リファラル採用とは</span>
          <IoMdArrowForward style={{ fontSize: 14 }} />
        </a>
        <a href="#whyChooseUs" className="link">
          <span className="linkText">リフカムが選ばれる理由</span>
          <IoMdArrowForward style={{ fontSize: 14 }} />
        </a>
        <a href="#cases" className="link">
          <span className="linkText">Refcomeの実績・導入事例</span>
          <IoMdArrowForward style={{ fontSize: 14 }} />
        </a>
      </div>

      <style jsx>{`
        .container {
          display: flex;
          justify-content: center;
          height: 60px;
          padding: 8px 20px;
          background: ${colors.brandGradient};
        }
        .link {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 250px;
          border-right: 1px solid ${colors.white};
          color: ${colors.white};
          gap: 5px;
        }
        .linkText {
          color: ${colors.white};
          font-size: 14px;
          font-weight: 500;
        }
        @media (${breakPoints.sp}) {
          .container {
            height: auto;
            padding: 4px 0;
            margin-top: 20px;
            flex-wrap: wrap;
          }
          .link {
            width: 50%;
            border-right: 0;
            padding: 4px 0;
          }
          .linkText {
            font-size: 12px;
          }
        }
      `}</style>
    </>
  );
};
