import React from "react";
import { Container } from "~/atoms/Container";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";
import ServiceProduct from "~/images/service-product.png";
import ServiceRpo from "~/images/service-rpo.png";

export const Services: React.FC<{}> = () => {
  return (
    <>
      <style jsx>{`
        .aboutReferralSession {
          content: "";
          display: block;
          height: 90px;
          margin-top: -90px;
          visibility: hidden;
        }
        .wrapper {
          background: ${colors.lightOrange};
          padding: 70px 30px;
        }
        .wrapper :global(.container) {
          flex-direction: column;
        }
        .heading {
          font-size: 36px;
          font-weight: bold;
          line-height: 1.7;
          margin: 0 0 24px;
          text-align: center;
        }
        .paragraph {
          font-size: 15px;
          line-height: 1.7;
          word-wrap: break-word;
          color: ${colors.lightText};
        }
        .subTitleLabel {
          color: ${colors.brand};
          font-weight: bold;
          text-align: center;
          margin-top: 40px;
        }
        .subTitle {
          font-size: 24px;
          font-weight: bold;
          text-align: center;
          letter-spacing: 1px;
          margin-top: 4px;
        }
        .serviceBoxes {
          align-items: center;
          display: flex;
          gap: 24px;
          justify-content: center;
          margin-top: 24px;
        }
        .serviceBox {
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;
          gap: 24px;
          background-color: ${colors.white};
          border-radius: 12px;
          height: 462px;
          width: 400px;
          padding: 16px;
        }
        .serviceTitle {
          font-size: 16px;
          font-weight: bold;
          height: 36px;
          width: 100%;
          border-bottom: solid 1px ${colors.black};
          color: ${colors.darkerText};
        }
        .descriptionList {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }
        .descriptionRow {
          display: flex;
          align-items: center;
          height: 42px;
        }
        .descriptionIcon {
          align-items: center;
          background-color: ${colors.lightGray};
          border-radius: 50%;
          display: flex;
          font-size: 20px;
          height: 40px;
          justify-content: center;
          width: 40px;
        }
        .descriptionWrapper {
          display: flex;
          flex-direction: column;
          margin-left: 16px;
          max-width: 312px;
          justify-content: center;
        }
        .descriptionTitle {
          font-weight: bold;
          font-size: 14px;
          color: ${colors.darkerText};
        }
        .description {
          font-size: 14px;
          color: ${colors.darkerText};
        }
        .image {
          width: 100%;
        }
        @media (${breakPoints.sp}) {
          .wrapper {
            padding: 50px 30px;
          }
          .heading {
            font-size: 24px;
          }
          .paragraph {
            font-size: 14px;
          }
          .spBreak {
            display: block;
          }
          .spBreak :global(span) {
            display: inline;
          }
          .serviceBoxes {
            flex-direction: column;
          }
          .serviceBox {
            width: 100%;
          }
          .descriptionIcon {
            display: none;
          }
          .descriptionWrapper {
            margin-left: 0;
          }
          .descriptionTitle {
            font-size: 12px;
          }
          .description {
            font-size: 12px;
          }
        }
        @media (${breakPoints.pc}) {
          .paragraph {
            text-align: center;
          }
        }
      `}</style>
      <div id="aboutReferral" className="aboutReferralSession" />
      <div className="wrapper">
        <Container>
          <h2 className="heading">
            <span>人手不足のお悩み</span>
            <br />
            <span>リファラル採用で解決します</span>
          </h2>
          <p className="paragraph">
            <span className="spBreak">
              <span>リファラル採用は、社員の大切な友人・知人を紹介してもらうことで選考を行う手法です。</span>
            </span>
            <span className="spBreak">
              <span>
                他の手法に比べ、早期の戦力化が特長であり、さらには内定率や定着率の高さ、採用コスト削減など多くのメリットがあります。
              </span>
            </span>
            <span className="spBreak">
              <span>
                リフカムは企業の採用課題に寄り添い、専属の担当者が導入から実行まで深く入り込んでサポートすることで人手不足のお悩みを解消します。
              </span>
            </span>
          </p>
          <h3 className="subTitleLabel">SERVICE</h3>
          <span className="subTitle">Refcomeの提供サービス</span>
          <div className="serviceBoxes">
            <div className="serviceBox">
              <div className="serviceTitle">
                <span>リファラル特化型クラウドツール</span>
              </div>
              <div className="descriptionList">
                <div className="descriptionRow">
                  <div className="descriptionIcon">
                    <span>👏</span>
                  </div>
                  <div className="descriptionWrapper">
                    <span className="descriptionTitle">タレントプール</span>
                    <span className="description">潜在層候補を一元管理してなーチャリング</span>
                  </div>
                </div>
                <div className="descriptionRow">
                  <div className="descriptionIcon">
                    <span>🎉</span>
                  </div>
                  <div className="descriptionWrapper">
                    <span className="descriptionTitle">コミュニケーションを円滑化</span>
                    <span className="description">人事・社員・友人の３者の連携を簡単・効率に</span>
                  </div>
                </div>
                <div className="descriptionRow">
                  <div className="descriptionIcon">
                    <span>🚀</span>
                  </div>
                  <div className="descriptionWrapper">
                    <span className="descriptionTitle">社内広報</span>
                    <span className="description">人事から自社の魅力や活動を発信し社員に届ける</span>
                  </div>
                </div>
              </div>
              <div>
                <img className="image" src={ServiceProduct} />
              </div>
            </div>
            <div className="serviceBox">
              <div className="serviceTitle">
                <span>リファラル特化型RPO</span>
              </div>
              <div className="descriptionList">
                <div className="descriptionRow">
                  <div className="descriptionIcon">
                    <span>📣</span>
                  </div>
                  <div className="descriptionWrapper">
                    <span className="descriptionTitle">専属の担当者がリファラル採用活動を運用</span>
                    <span className="description">850社以上の支援実績で企画・実行までを運用</span>
                  </div>
                </div>
                <div className="descriptionRow">
                  <div className="descriptionIcon">
                    <span>📝</span>
                  </div>
                  <div className="descriptionWrapper">
                    <span className="descriptionTitle">社内へのオンボーディングを徹底サポート</span>
                    <span className="description">社内説明会の実施や社内広報の実務をリード</span>
                  </div>
                </div>
                <div className="descriptionRow">
                  <div className="descriptionIcon">
                    <span>🚀</span>
                  </div>
                  <div className="descriptionWrapper">
                    <span className="descriptionTitle">各種採用施策の設計</span>
                    <span className="description">プロジェクト組成や目標・進捗の管理をサポート</span>
                  </div>
                </div>
              </div>
              <div>
                <img className="image" src={ServiceRpo} />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
